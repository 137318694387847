/* global $ */

/*
List of all errors/warnings

/**
 *
 *
 */
(function (sculpteo) {
  "use strict";

  var ERROR = (sculpteo.ERROR = {});
  ERROR.INVALID_DESIGN = "invalid_design";
  ERROR.IS_REPAIRED = "is_repaired";
  ERROR.NEED_REPAIR = "need_repair";
  ERROR.ORDER_NO_AUTH_SIZECONSTRAINTS = "order_no_auth_sizeconstraints";
  ERROR.ORDER_NO_AUTH_PRODUCTCONSTRAINTS = "order_no_auth_productconstraints";
  ERROR.SCALE_MAX_FITS_WHITE_PLASTIC = "scale_max_fits_white_plastic";
  ERROR.SCALE_MAX = "scale_max";
  ERROR.SCALE_MIN_MATERIAL = "scale_min_material";
  ERROR.SCALE_MIN = "scale_min";
  ERROR.ONE_FREE_OBJ_ALLOWED = "one_free_obj_allowed";
  ERROR.SOLIDITY_WILL_BREAK = "solidity_will_break";
  ERROR.SOLIDITY_MAY_BREAK = "solidity_may_break";
  ERROR.AVAILABLE_ONLY_BATCH_CONTROL = "available_only_batch_control";
  ERROR.AVAILABLE_ONLY_SINGLEPART = "available_only_singlepart";
  ERROR.MAT_NOT_AVAIL_PRODUCTION_CENTER = "mat_not_avail_production_center";
  ERROR.MATERIAL_NOT_AVAILABLE = "material_not_available";
  ERROR.NO_PRINTER_AVAILABLE = "no_printer_available";
  ERROR.NO_DELAYS_AVAILABLE = "no_delays_available";
  ERROR.IS_REMOVED = "is_removed";
  ERROR.ADD_TO_CART_ERROR = "add_to_cart_error";
  ERROR.TRIBOFINISHING_GUIDELINES = "tribofinishing_guidelines";
  ERROR.MIN_DIM_GUIDELINES = "min_dim_guidelines";
  ERROR.DYEMANSION_GUIDELINES = "dyemansion_guidelines";
  ERROR.PLASTIC_BLACK_GUIDELINES = "plastic_black_guidelines";

  var WARNING = (sculpteo.WARNING = {});
  WARNING.MULTICOLOR_NO_COLOR = "multicolor_no_color";
  WARNING.SOLIDITY_MAY_BREAK = "solidity_may_break";
  WARNING.BATCH_NO_QUALIFIES = "batch_no_qualifies";
  WARNING.BATCH_NO_QUALIFIES_SCALE_TOO_LARGE =
    "batch_no_qualifies_scale_too_large";
  WARNING.THICKENING_MULTICOLOR = "thickening_multicolor";
  WARNING.SCALE_VALUE_NUMBER = "scale_value_number";
  WARNING.MATERIAL_IS_STAFF_ONLY = "material_is_staff_only";
  WARNING.AUTO_SWITCH_STANDARD = "auto_switch_standard";

  var INFO = (sculpteo.INFO = {});
  INFO.PRINT_DOSSIER_PREPARED = "print_dossier_prepared";
  INFO.BATCH_QUALIFIES = "batch_qualifies";
  INFO.DISABLED_TOOL_CLICK = "disabled_tool_click";
  INFO.TOOL_SETTINGS = "tool_settings";
  INFO.TOOLS_RESET = "tools_reset";
  INFO.HOLLOWING_PURPOSE = "hollowing_purpose";

  var initMessagesTranslations = function () {
    sculpteo.messagesTranslations = {
      need_repair: gettext("Design needs repair"),
      order_no_auth_sizeconstraints: gettext(
        "Order Not Authorized by SizeConstraints"
      ),
      order_no_auth_productconstraints: gettext(
        "Order Not Authorized by Products Constraints"
      ),
      scale_max_fits_white_plastic: gettext(
        "Maximum size for this finish is XXXX. But check the SLS PA12 material, the design would fit."
      ),
      scale_max: gettext("Maximum size in this material is XXXX"),
      scale_min_material: gettext("Minimum size for this material is XXXX"),
      scale_min: gettext("Minimum size is XXXX"),
      one_free_obj_allowed: gettext(
        "Sorry, only one free object per cart allowed"
      ),
      available_only_batch_control: gettext(
        "This option is only available if batch control is enabled"
      ),
      available_only_singlepart: gettext(
        "This option is only available for single-part designs"
      ),
      mat_not_avail_production_center: gettext(
        "This material is not available for the selected production center"
      ),
      material_not_available: gettext("This material is currently unavailable"),
      no_printer_available: "XXXX", //the text will be in the data and will get replace by it
      no_delays_available: "XXXX",
      is_removed: "XXXX", //the text will be in the data and will get replace by it
      disabled_tool_click: "XXXX", //the text will be in the data and will get replace by it
      tribofinishing_guidelines: "XXXX", //the text will be in the data and will get replace by it
      min_dim_guidelines: "XXXX", //the text will be in the data and will get replace by it
      dyemansion_guidelines: "XXXX", //the text will be in the data and will get replace by it
      plastic_black_guidelines: "XXXX", //the text will be in the data and will get replace by it
      tool_settings: gettext(
        "You are currently using one of our 3D tools. To return to the 3D Printing Settings, you need to go back to the Dashboard"
      ),
      batch_qualifies: gettext("Your item now qualifies for Batch Control"),
      batch_no_qualifies: gettext(
        "Your item no longer qualifies for Batch Control"
      ),
      batch_no_qualifies_scale_too_large: gettext(
        "Your item no longer qualifies for Batch Control<br>Your design's scale is too large"
      ),
      solidity_will_break: gettext(
        "Design has fine details which will break, please review its solidity"
      ),
      multicolor_no_color: gettext(
        "Your design does not contain any color, you may consider to use a more robust material (plastic ones, for example)"
      ),
      tools_reset: gettext("All the tools has been reset on this design"),
      solidity_may_break: gettext(
        "Design has small details which might break, please check its solidity"
      ),
      hollowing_one_hole: gettext("hollowing_one_hole"),
      hollowing_design_too_small: gettext("hollowing_design_too_small"),
      hollowing_not_hollowed: gettext("hollowing_not_hollowed"),
      hollowing_purpose: gettext(
        "We have noticed that your part could be optimized to reduce cost. Have you tried using our hollowing tool?"
      ),
      thickening_multicolor: gettext(
        "Thickening a multicolor design may change the texture and colors slightly."
      ),
      metal_some_not_closed: gettext(
        "Your design contains shapes that are not fully closed - these can not currently be laser cut in metal. See the Review tab for more details"
      ),
      metal_cut_types: gettext(
        "Metal cut parts do not support surface engrave. These cut types have been removed while metal is selected."
      ),
      metal_none_closed: gettext(
        "Laser cut objects in metal need at least one fully closed cut."
      ),
      lasercut_all_ignored: gettext(
        "Your design has not set any cuts or engraves. Please set at least one path or object to be a cut or an engrave to continue."
      ),
      scale_value_number: gettext("Scale/size value must be a number"),
      material_is_staff_only: gettext(
        "<b>WARNING</b>: the current material is <b>staff only</b>"
      ),
      auto_switch_standard: gettext(
        "Production mode <b>ECONOMY</b> is not available for this configuration<br>Production mode changed to <b>STANDARD</b>"
      ),
      min_personalized_quantity: gettext("Quantity cannot be under XXXX"),
      max_personalized_quantity: gettext("Quantity cannot be more than XXXX"),
      personalized_quantity_not_number: gettext("Quantity has to be a number"),
      min_quantity: "XXXX", //the text will be in the data and will get replace by it
    };
  };

  // Not sure why this was done this way but i'm keeping the previous behaviour for AMT (c.f AMT-1636 / commit 4322f4ae)
  // This would cause a problem since we have code that depends on this being inited that is run before doc ready
  // We just now actually check if gettext is defined to decide if we defer the call.
  // Please remove that the day we have modern js dependency management or the AMT ticket is solved differently
  if (typeof gettext !== "function") {
    console.log("gettext undefined, loading translations later");
    $().ready(function () {
      initMessagesTranslations();
    });
  } else {
    initMessagesTranslations();
  }
})((window.sculpteo = window.sculpteo || {}));
