(function (scope) {
  "use strict";

  var BatchControlMixin = (scope.BatchControlMixin = function () {});

  /**
   * Check if batch control is available for the current design's configuration.
   * Batch control is available if :
   * - current material is eligible
   * - quantity is > 20
   * - item is not too big
   *
   * @param {Design} design
   * @return {boolean}
   */
  BatchControlMixin.prototype.isBatchControlAvailableForDesign = function (
    design
  ) {
    var response = {
      available: false,
      error_id: undefined,
    };

    if (design.order.quantity < 20) {
      response.available = false;
      return response;
    }
    var allowed_material = design.material.series_configured;
    if (!allowed_material) {
      response.available = false;
      return response;
    }
    // if (size.x * scale > 18.0 || size.y * scale > 18.0 || size.z * scale > 18.0) {
    // 	return false;
    // }

    //check if the model in that material fit in the printer size in all rotation possible
    if (design.material.printer) {
      var size = design.size.get();
      var scale = design.size.unitFactor();
      var bbox = [size.x * scale, size.y * scale, size.z * scale];
      var print_box = [
        design.material.printer.max_x,
        design.material.printer.max_y,
        design.material.printer.max_z,
      ];

      bbox.sort(function (a, b) {
        return a - b;
      });

      print_box.sort(function (a, b) {
        return a - b;
      });

      if (
        bbox[0] > print_box[0] ||
        bbox[1] > print_box[1] ||
        bbox[2] > print_box[2]
      ) {
        //errors.push(gettext("Your design's scale is too large for Batch Control"));

        response.available = false;
        response.error_id = "design_scale_too_large";
        return response;
      }
    }

    response.available = true;
    return response;
  };
})((window.sculpteo = window.sculpteo || {}));
